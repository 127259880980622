export const CREATE_CLOUD_STORAGE_REQUEST = "CREATE_CLOUD_STORAGE_REQUEST";
export const CREATE_CLOUD_STORAGE_REQUEST_SUCCESS = "CREATE_CLOUD_STORAGE_REQUEST_SUCCESS";
export const CREATE_CLOUD_STORAGE_REQUEST_FAILED = "CREATE_CLOUD_STORAGE_REQUEST_FAILED";

export const FETCH_CLOUD_STORAGES_REQUEST = "FETCH_CLOUD_STORAGES_REQUEST";
export const FETCH_CLOUD_STORAGES_REQUEST_SUCCESS = "FETCH_CLOUD_STORAGES_REQUEST_SUCCESS";
export const FETCH_CLOUD_STORAGES_REQUEST_FAILED = "FETCH_CLOUD_STORAGES_REQUEST_FAILED";

export const DELETE_CLOUD_STORAGE_REQUEST = "DELETE_CLOUD_STORAGE_REQUEST";
export const DELETE_CLOUD_STORAGE_REQUEST_SUCCESS = "DELETE_CLOUD_STORAGE_REQUEST_SUCCESS";
export const DELETE_CLOUD_STORAGE_REQUEST_FAILED = "DELETE_CLOUD_STORAGE_REQUEST_FAILED";

export const FETCH_CLOUD_STORAGE_REQUEST = "FETCH_CLOUD_STORAGE_REQUEST";
export const FETCH_CLOUD_STORAGE_REQUEST_SUCCESS = "FETCH_CLOUD_STORAGE_REQUEST_SUCCESS";
export const FETCH_CLOUD_STORAGE_REQUEST_FAILED = "FETCH_CLOUD_STORAGE_REQUEST_FAILED";

export const UPDATE_CLOUD_STORAGE_REQUEST = "UPDATE_CLOUD_STORAGE_REQUEST";
export const UPDATE_CLOUD_STORAGE_REQUEST_SUCCESS = "UPDATE_CLOUD_STORAGE_REQUEST_SUCCESS";
export const UPDATE_CLOUD_STORAGE_REQUEST_FAILED = "UPDATE_CLOUD_STORAGE_REQUEST_FAILED";
