import { useEffect, useState } from "react";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import { ReactComponent as Cloud } from '../../../src/assets/svgs/cloud.svg';
import { ReactComponent as CloudLogo } from '../../../src/assets/svgs/cloud-logo.svg';
import AppBar from "../../components/AppBar/AppBar";
import { useNavigate, useParams } from "react-router";
import CardLoader from "../../components/loader/cardLoader";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import useUrlQuery from "../../hooks/useUrlQuery";
import TopBar from "../../components/TopBar/TopBar";
import Pagination from "../../components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { CloudStorageMenu } from "./components/CloudStorageMenu";
import { useGetCloud } from "../../services/CloudStorages/useQueries";
import { useCloudStore } from "../../zustand-store/cloudstorages";
const pageSize = 11;

export default function CloudStoragePage() {
  const navigate = useNavigate();
  let urlQuery = useUrlQuery();
  dayjs.extend(relativeTime);
  
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const currentPage = parseInt(urlQuery.get("page"));

  const cloud_obj = useCloudStore((state) => state.cloud_obj);
  const setCloudStorage = useCloudStore((state) => state.setCloudStorage);

  const filters = [
    {
      id: "quick_filter",
      name: "Quick Filter",
      options: [
        {
          label: "Owned by me",
          value: '{"and":[{"==":[{"var":"owner"},"<username>"]}]}',
        },
        {
          label: "AWS Storage",
          value: '{"==":[{"var":"storage_type"},"AWS"]}',
        },
      ],
    },
  ];



  const getCloudQuery = useGetCloud({
    queryConfig: {
      queryKey: [pageSize, currentPage, searchValue, appliedFilters],
      apiParams: {
        page: currentPage,
        page_size: pageSize,
        search: searchValue,
        ...(appliedFilters.length > 1
          ? {
            filter: JSON.stringify({
              and: appliedFilters.map((filter) => JSON.parse(filter))
            })

          } : {
            filter: appliedFilters[0]
          })

      },
      enabled: true,
      onSuccess: (data) => setCloudStorage(data)

    }
  })






  const filterHandler = (event) => {
    if (event.target.checked) {
      setAppliedFilters([...appliedFilters, event.target.value]);
    } else {
      setAppliedFilters(
        appliedFilters.filter((filterTag) => filterTag !== event.target.value)
      );
    }
  };

  console.log(searchValue);
  return (
    <>
      <AppBar>
        <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white dark:text-gray-100">
              Cloud Storages
            </h1>
          </div>
        </header>
      </AppBar>
      <main className=" -mt-32 mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-white dark:bg-audino-navy px-5 py-6 shadow sm:px-6 min-h-full">
          <TopBar
            filters={filters}
            onFilter={filterHandler}
            appliedFilters={appliedFilters}
            setSearchValue={setSearchValue}
          >
            <PrimaryButton
              onClick={() => navigate("/cloud-storages/create")}
            >
              <Cloud className="h-5 w-5 text-white" />
            </PrimaryButton>
          </TopBar>
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-6">
            {/* new project  */}
            {getCloudQuery.isLoading || getCloudQuery.isRefetching
              ? [...Array(5).keys()].map((load) => (
                <li
                  key={`CardLoader-${load}`}
                  className="col-span-1 divide-y divide-gray-200 dark:divide-audino-gray rounded-lg bg-white dark:bg-audino-midnight shadow cursor-pointer py-8 sm:py-0"
                  onClick={() => navigate("create")}
                >
                  <CardLoader />
                </li>
              ))
              : cloud_obj.results.map((cloud) => (
                <li
                  key={cloud.id}
                  className="col-span-1 dark:border dark:border-audino-gray rounded-lg bg-white dark:bg-audino-midnight shadow"
                >
                  <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-md font-medium text-gray-900 dark:text-white">
                          {`#${cloud.id}: ${cloud.display_name}`}
                        </h3>
                      </div>
                      <p className="mt-0.5 truncate text-sm text-gray-500 dark:text-white">
                        Provider: {cloud.provider_type}
                      </p>
                      <p className="mt-0.5 truncate text-sm text-gray-500 dark:text-white">
                        Created by <span className="dark:text-audino-primary">{cloud.owner.username}</span>
                      </p>
                      <p className="mt-0.5 truncate text-sm text-gray-500 dark:text-white">
                        Last updated {dayjs(cloud.updated_date).fromNow()}
                      </p>
                      <div className="mt-0.5 truncate text-sm text-gray-500 dark:text-white flex gap-2">
                      Status: {cloud.id === cloud.id ? (<p className="text-audino-primary">AVAILABLE</p>) : (<p className="text-red-500">FORBIDDEN</p>)}
                      </div>
                    </div>
                  </div>
                  <div className="-mt-4 flex justify-end px-3 py-2">
                    <CloudStorageMenu />
                  </div>
                </li>
              ))}

          </ul>
          {/* pagination */}
          <Pagination
            resultObj={cloud_obj}
            pageSize={pageSize}
            currentPage={currentPage}
          />

        </div>

      </main>
    </>
  );
}
