import toast from "react-hot-toast";
import { call, takeEvery, put } from "redux-saga/effects";
import { CREATE_CLOUD_STORAGE_REQUEST, DELETE_CLOUD_STORAGE_REQUEST,  FETCH_CLOUD_STORAGES_REQUEST, FETCH_CLOUD_STORAGE_REQUEST, UPDATE_CLOUD_STORAGE_REQUEST } from "../Constants/cloudTypes";
import { createCloudStorageFailed, createCloudStorageSuccess, deleteCloudStorageFailed, deleteCloudStorageSuccess, fetchCloudStorageFailed, fetchCloudStorageSuccess, fetchCloudStoragesFailed, fetchCloudStoragesSuccess, updateCloudStorageFailed, updateCloudStorageSuccess } from "../Actions/cloudActions";
import { createCloudStorageApi, fetchCloudStoragesApi } from "../../services/cloudstorages.services";

function* createCloudStorage(action) {
  try {
    const { payload, callback } = action.payload;
    const data = yield call(createCloudStorageApi, payload);
    toast.success('Cloud storage created successfully');
    yield put(createCloudStorageSuccess({ data, callback }));
  } catch (error) {
    toast.error(error.message?.detail);
    yield put(createCloudStorageFailed(error));
  }
}

function* fetchCloudStorages(action) {
  try {
    const { payload } = action.payload;
    const data = yield call(fetchCloudStoragesApi, payload);
    yield put(fetchCloudStoragesSuccess({ data }));
  } catch (error) {
    toast.error(error.message);
    yield put(fetchCloudStoragesFailed(error));
  }
}

// function* deleteProject(action) {
//   try {
//     const { payload, callback } = action.payload;
//     const data = yield call(deleteProjectApi, payload);
//     toast.success('Project deleted successfully');
//     yield put(deleteProjectSuccess({ data: payload, callback }));
//   } catch (error) {
//     toast.error(error.message);
//     yield put(deleteProjectFailed(error));
//   }
// }

// function* fetchProject(action) {
//   try {
//     const { payload } = action.payload;
//     const data = yield call(fetchProjectApi, payload);
//     yield put(fetchProjectSuccess({ data }));
//   } catch (error) {
//     toast.error(error.message);
//     yield put(fetchProjectFailed(error));
//   }
// }

// function* updateProject(action) {
//   try {
//     const { payload , callback} = action.payload;
//     const data = yield call(updateProjectApi, payload);
//     yield put(updateProjectSuccess({ data: data.data, callback }));
//   } catch (error) {
//     toast.error(error.message?.detail);
//     yield put(updateProjectFailed(error));
//   }
// }

function* cloudstorageSaga() {
  yield takeEvery(CREATE_CLOUD_STORAGE_REQUEST, createCloudStorage);
  yield takeEvery(FETCH_CLOUD_STORAGES_REQUEST, fetchCloudStorages);
//   yield takeEvery(DELETE_PROJECT_REQUEST, deleteProject);
//   yield takeEvery(FETCH_PROJECT_REQUEST, fetchProject);
//   yield takeEvery(UPDATE_PROJECT_REQUEST, updateProject);
}

export default cloudstorageSaga;
