const langOptions = [
  {
    label: "English",
    value: "en",
  },
  { label: "Hindi", value: "hi" },
  { label: "Kruti", value: "kd010" },
  // {
  //   label: "Spanish",
  //   value: "es",
  // },
  // { label: "Amharic", value: "am" },
  { label: "Arabic", value: "ar" },
  { label: "Bangla", value: "bn" },
  // { label: "Belarusian", value: "be" },
  // // { label: "Bulgarian", value: "bg" },
  // { label: "Chinese (Hong Kong)", value: "yue-hant" },
  // { label: "Chinese Simplified", value: "zh" },
  // { label: "Chinese Traditional", value: "zh-hant" },
  // { label: "French", value: "fr" },
  // { label: "German", value: "de" },
  // { label: "Greek", value: "el" },
  { label: "Gujarati", value: "gu" },
  // { label: "Hebrew", value: "he" },
  // { label: "Italian", value: "it" },
  // { label: "Japanese", value: "ja" },
  { label: "Kannada", value: "kn" },
  { label: "Malayalam", value: "ml" },
  { label: "Marathi", value: "mr" },
  { label: "Nepali", value: "ne" },
  { label: "Odia", value: "or" },
  // { label: "Persian", value: "fa" },
  // { label: "Portuguese (Brazil)", value: "pt" },
  { label: "Punjabi", value: "pa" },
  // { label: "Russian", value: "ru" },
  { label: "Sanskrit", value: "sa" },
  // { label: "Serbian", value: "sr" },
  { label: "Sinhala", value: "si" },
  // { label: "Spanish", value: "es" },
  { label: "Tamil", value: "ta" },
  { label: "Telugu", value: "te" },
  // { label: "Tigrinya", value: "ti" },
  // { label: "Ukrainian", value: "uk" },
  { label: "Urdu", value: "ur" },
  // { label: "Vietnamese", value: "vi" },
];

export default langOptions;
