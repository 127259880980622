export default function CardLoader() {
  return (
    <div role="status" className="max-w-md p-4 space-y-4 animate-pulse md:p-6 ">
      <div className="flex items-center justify-between">
        <div>
          <div className="h-2.5 bg-gray-300 dark:bg-audino-midnight rounded-full  w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 dark:bg-audino-midnight rounded-full "></div>
        </div>
        <div className="h-2.5 bg-gray-300 dark:bg-audino-midnight rounded-full  w-12"></div>
      </div>
      <div className="flex items-center justify-between pt-4">
        <div>
          <div className="h-2.5 bg-gray-300 dark:bg-audino-midnight rounded-full  w-24 mb-2.5"></div>
          <div className="w-32 h-2 bg-gray-200 dark:bg-audino-midnight rounded-full"></div>
        </div>
        <div className="h-2.5 bg-gray-300 dark:bg-audino-midnight rounded-full w-12"></div>
      </div>
    </div>
  );
}
